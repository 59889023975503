import { createBrowserRouter } from 'react-router-dom'
import React, { Suspense } from 'react'
import { AuthLayout } from '@/app/layouts'
import WrapperLayout from '@/app/layouts/wrapper-layout'
import { SpinnerLoader } from '@/shared/ui/SpinnerLoader'
const StatsPage = () => import('@/app/pages/stats')
const ReferalPage = () => import('@/app/pages/cabinet/referal')
const WorkersPage = () => import('@/app/pages/cabinet/workers')
const MainPage = () => import('@/app/pages/main')
const LoginPage = () => import('@/app/pages/auth/login')
const SignupPage = () => import('@/app/pages/auth/signup')
const CabinetPage = () => import('@/app/pages/cabinet')

const FaQPage = () => import('@/app/pages/main/faq')
const HomePage = () => import('@/app/pages/cabinet/home')
const HistoryPage = () => import('@/app/pages/cabinet/history')
const PayoutPage = () => import('@/app/pages/cabinet/payouts')
const SettingsPage = () => import('@/app/pages/cabinet/settings')
const PolicyPage = () => import('@/app/pages/main/policy')
const PolicyEnPage = () => import('@/app/pages/main/policy/en')
const TermsInUsePage = () => import('@/app/pages/main/terms-in-use')
const TermsInUseEnPage = () => import('@/app/pages/main/terms-in-use/en')
const VerifyPage = () => import('@/app/pages/auth/verify')
const ResetPage = () => import('@/app/pages/auth/reset')
const ObserverPage = () => import('@/app/pages/cabinet/observers')
const CabinetFaQPage = () => import('@/app/pages/cabinet/faq')
const SubAccountsPage = () => import('@/app/pages/cabinet/subaccounts')
const FaAuthPage = () => import('@/app/pages/auth/2fa')
const ObserverLinkPage = () => import('@/app/pages/observer')
const PersonalSettings = () => import('@/app/pages/cabinet/personal_settings')

const SuspenseWrapper = (callback: () => any) => {
    const LazyElement = React.lazy(callback)

    return (
        <Suspense fallback={<SpinnerLoader />}>
            <LazyElement />
        </Suspense>
    )
}

const router = createBrowserRouter([
    {
        path: "stats",
        element: SuspenseWrapper(StatsPage),
      },    
    {
        path: '',
        element: <WrapperLayout />,
        children: [
            {
                path: '/',
                element: SuspenseWrapper(MainPage),
            },
            {
                path: '/faq',
                element: SuspenseWrapper(FaQPage),
            },
            // { path: '/faq/en', element: <FaqEnPage /> },
            // { path: '/faq/ru', element: <FaqRuPage /> },
            // { path: '/faq/es', element: <FaqEsPage /> },
            // { path: '/faq/pt', element: <FaqPtPage /> },
            {
                path: '/policy',
                element: SuspenseWrapper(PolicyPage),
            },
            {
                path: '/policy/en',
                element: SuspenseWrapper(PolicyEnPage),
            },
            {
                path: '/terms-in-use',
                element: SuspenseWrapper(TermsInUsePage),
            },
            {
                path: '/terms-in-use/en',
                element: SuspenseWrapper(TermsInUseEnPage),
            },
            {
                path: '/auth',
                element: <AuthLayout />,
                children: [
                    {
                        path: 'login',
                        element: SuspenseWrapper(LoginPage),
                    },
                    {
                        path: '2fa/:oauth',
                        element: SuspenseWrapper(FaAuthPage),
                    },
                    {
                        path: 'reset',
                        element: SuspenseWrapper(ResetPage),
                    },
                    {
                        path: 'verify',
                        element: SuspenseWrapper(VerifyPage),
                    },
                    {
                        path: 'signup',
                        element: SuspenseWrapper(SignupPage),
                    },
                ],
            },
            {
                path: '/observer-link/:token',
                element: SuspenseWrapper(ObserverLinkPage),
            },
            {
                path: 'cabinet',
                element: SuspenseWrapper(CabinetPage),
                children: [
                    {
                        path: '',
                        element: SuspenseWrapper(HomePage),
                    },
                    {
                        path: 'personal_settings',
                        element: SuspenseWrapper(PersonalSettings),
                    },
                    {
                        path: 'workers',
                        element: SuspenseWrapper(WorkersPage),
                    },
                    {
                        path: 'referal',
                        element: SuspenseWrapper(ReferalPage),
                    },
                    {
                        path: 'faq',
                        element: SuspenseWrapper(CabinetFaQPage),
                    },
                    {
                        path: 'subaccounts',
                        element: SuspenseWrapper(SubAccountsPage),
                    },
                    {
                        path: 'observers',
                        element: SuspenseWrapper(ObserverPage),
                    },
                    {
                        path: 'history',
                        element: SuspenseWrapper(HistoryPage),
                    },
                    {
                        path: 'payout',
                        element: SuspenseWrapper(PayoutPage),
                    },
                    {
                        path: 'settings',
                        element: SuspenseWrapper(SettingsPage),
                    },
                ],
            },
        ],
    },
])
export { router }
