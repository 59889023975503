import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    CREATE_SUB_ACCOUNT,
    GET_ALL_SUB_ACCOUNTS,
    GET_STATISTICS,
    GET_SUB_ACCOUNT_CHART,
    GET_SUB_ACCOUNT_EARNINGS,
    GET_SUB_ACCOUNT_PAYOUT,
    GET_SUB_ACCOUNT_STATISTICS,
    GET_SUB_ACCOUNT_STRATUM,
    GET_SUB_ACCOUNT_SUMMARY,
    GET_SUB_ACCOUNT_WORKER,
    GET_USER_DETAILS,
    UPDATE_SUB_ACCOUNT_REWARD,
    UPDATE_SUB_ACCOUNT_WALLET,
} from '@/store/sub_accounts/variables'
import {
    createSubAccount,
    editSubAccountWallet,
    getStatistics,
    getSubAccountChart,
    getSubAccounts,
    getSubAccountsEarning,
    getSubAccountsPayouts,
    getSubAccountsSummary,
    getSubAccountStratumUrls,
    updateSubAccountRewardApi,
} from '@/store/sub_accounts/api'
import {
    CURRENT_SUBACCOUNT_KEY,
    getSubAccountStatistics,
    getSubAccountWorkers,
    getUserDetailApi,
    IEarningDto,
    IPayoutDto,
    IPersonalDetail,
    ISubAccountInfoDto,
    IWorker,
    IWorkersReqParams,
    IWorkerStatistics,
    StatisticResponseDto,
    SubAccountCreateBodyDto,
    SubAccountSummaryDto,
} from '@/entities/cabinet'
import { IPaginate } from '@/entities'

export interface ISubAccountState {
    loading: boolean
    stratumUrls: Array<any>
    subAccounts: Array<any>
    earnings: Array<IEarningDto>
    earning: IPaginate<Array<IEarningDto>> | null
    payout: IPaginate<Array<IPayoutDto>> | null
    subAccountChart: {
        hashrates: number[]
        now: any
    }
    subAccountSummary: Partial<SubAccountSummaryDto>
    subAccountPayout: IPayoutDto[]
    statistics: StatisticResponseDto[]
    subAccountInfo: Partial<ISubAccountInfoDto>
    currentSubAccount: string
    subAccountStatistics: IWorkerStatistics | null
    workers: IWorker[]
    allWorkers: IWorker[]
    dialogs: { [key in string]: boolean }
    userDetail: IPersonalDetail | null
}
const initialState: ISubAccountState = {
    loading: false,
    stratumUrls: [],
    subAccounts: [],
    subAccountChart: { hashrates: [], now: '' },
    subAccountSummary: {},
    earnings: [],
    statistics: [],
    payout: null,
    subAccountStatistics: null,
    earning: null,
    workers: [],
    allWorkers: [],
    subAccountInfo: {},
    subAccountPayout: [],
    currentSubAccount: '',
    dialogs: {},
    userDetail: null,
}

const getAllSubAccounts$ = createAsyncThunk(GET_ALL_SUB_ACCOUNTS, async () => {
    const response = await getSubAccounts()
    return Promise.resolve(response as ISubAccountInfoDto[])
})

const getSubAccountSummary$ = createAsyncThunk(
    GET_SUB_ACCOUNT_SUMMARY,
    async (name: string, { signal }) => {
        const response = await getSubAccountsSummary(name, { signal })
        const result = response.data as SubAccountSummaryDto
        result.crypto_currency = 'BTC'
        return Promise.resolve(result)
    }
)

const getSubAccountPayout$ = createAsyncThunk(
    GET_SUB_ACCOUNT_PAYOUT,
    async ({ name, params }: { name: string; params?: any }, thunkAPI) => {
        const response = await getSubAccountsPayouts(
            name,
            {
                signal: thunkAPI.signal,
            },
            params
        )
        return Promise.resolve(response.data)
    }
)

const getSubAccountStatistics$ = createAsyncThunk(
    GET_SUB_ACCOUNT_STATISTICS,
    async (params: IWorkersReqParams) => {
        const response = await getSubAccountStatistics(params)
        return Promise.resolve(response)
    }
)

const getSubAccountWorkers$ = createAsyncThunk(
    GET_SUB_ACCOUNT_WORKER,
    async (params: IWorkersReqParams) => {
        const response = await getSubAccountWorkers(params)
        return Promise.resolve(response)
    }
)

const createSubAccount$ = createAsyncThunk(
    CREATE_SUB_ACCOUNT,
    async (body: SubAccountCreateBodyDto) => {
        return createSubAccount(body)
            .then((res: any) => {
                return Promise.resolve(res.data)
            })
            .catch((e: any) => {
                if (e.response.data?.title) {
                    e.response.data.message = e.response.data.title
                }
                return Promise.reject(e.response.data || 5000)
            })
    }
)

const updateSubAccountWallet = createAsyncThunk(
    UPDATE_SUB_ACCOUNT_WALLET,
    async (body: { sub_account_id: number; wallet_address: string }) => {
        return editSubAccountWallet(body)
            .then((res: any) => {
                return Promise.resolve(res.data)
            })
            .catch((e) => {
                return Promise.reject(e.response?.data)
            })
    }
)

const updateSubAccountReward$ = createAsyncThunk(
    UPDATE_SUB_ACCOUNT_REWARD,
    async (
        body: Partial<{
            sub_account_id: number
            virtual_sub_accounts: ISubAccountInfoDto['virtual_sub_accounts']
        }>
    ) => {
        return updateSubAccountRewardApi(body)
            .then((res: any) => {
                return Promise.resolve(res.data)
            })
            .catch((e) => {
                return Promise.reject(e.response?.data)
            })
    }
)

const getUserDetails = createAsyncThunk(GET_USER_DETAILS, async () => {
    return getUserDetailApi().then((res) => Promise.resolve(res))
})

const getSubAccountStratum$ = createAsyncThunk(
    GET_SUB_ACCOUNT_STRATUM,
    async (name: string) => {
        return getSubAccountStratumUrls(name).then(
            (response: any) => response.data
        )
    }
)

const getSubAccountEarnings$ = createAsyncThunk(
    GET_SUB_ACCOUNT_EARNINGS,
    async ({ name, params }: { name: string; params?: any }, { signal }) => {
        const response = await getSubAccountsEarning(name, { signal }, params)
        return Promise.resolve(response.data)
    }
)

const getStatistics$ = createAsyncThunk(GET_STATISTICS, async () => {
    const response = await getStatistics()
    return Promise.resolve(
        ['BTC', 'BCH', 'LTC'].map(
            (key) => (response.data as any)[key] as StatisticResponseDto
        )
    )
})

const getSubAccountChart$ = createAsyncThunk(
    GET_SUB_ACCOUNT_CHART,
    async (
        body: { sub_account_name: string; interval: string },
        { signal }
    ) => {
        const response = await getSubAccountChart(body, signal)
        return Promise.resolve(response.data)
    }
)

export const subAccountSlice = createSlice({
    name: 'subaccount',
    initialState,
    reducers: {
        setCurrentSubAccount(state, { payload }) {
            state.currentSubAccount = payload
            const findSubAccount = state.subAccounts.find(
                (val) => val.name === payload
            )
            if (findSubAccount) {
                state.subAccountInfo = findSubAccount
            }
        },
        setCurrentSubAccountInfo(state, { payload }) {
            state.subAccountInfo = payload
        },
        setLoading(state, { payload }) {
            state.loading = payload
        },
        setDialogs(state, { payload }) {
            const { key, value } = payload
            state.dialogs[key] = value
            state.dialogs = { ...state.dialogs }
        },
        setWorkersForFilter(state, { payload }) {
            state.workers = [...payload]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSubAccountEarnings$.fulfilled, (state, action) => {
            state.earnings = action.payload as any
            if (!Array.isArray(state.earnings)) {
                state.earning = action.payload
            }
            state.loading = false
        })
        builder.addCase(
            getAllSubAccounts$.fulfilled,
            (state, { payload }: { payload: any }) => {
                state.subAccounts = (payload as any).sort((a: any, b: any) =>
                    a.name.localeCompare(b.name)
                )
                if (payload.length) {
                    const lastSubAccount = localStorage.getItem(
                        CURRENT_SUBACCOUNT_KEY
                    )
                    if (lastSubAccount) {
                        const subAccount = JSON.parse(lastSubAccount)
                        const actualSubAccount = payload.find(
                            (res: any) => res.name === subAccount.name
                        )
                        if (actualSubAccount) {
                            state.currentSubAccount = actualSubAccount.name
                            state.subAccountInfo = actualSubAccount
                        }
                    } else {
                        state.currentSubAccount = payload[0].name
                        state.subAccountInfo = payload[0]
                    }
                }
            }
        )
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.userDetail = action.payload
        })
        builder.addCase(getSubAccountSummary$.fulfilled, (state, action) => {
            state.subAccountSummary = action.payload as any
            state.loading = false
        })
        builder.addCase(getSubAccountSummary$.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(getSubAccountPayout$.fulfilled, (state, action) => {
            state.subAccountPayout = action.payload as any
            state.payout = action.payload
            state.loading = false
        })
        builder.addCase(getSubAccountWorkers$.fulfilled, (state, action) => {
            state.workers = action.payload
            state.allWorkers = action.payload
        })
        builder.addCase(getSubAccountStatistics$.fulfilled, (state, action) => {
            state.subAccountStatistics = action.payload
        })
        builder.addCase(createSubAccount$.fulfilled, (state, action) => {
            state.subAccounts.push(action.payload)
        })
        builder.addCase(getSubAccountStratum$.fulfilled, (state, action) => {
            state.stratumUrls = action.payload as any
        })
        builder.addCase(getStatistics$.fulfilled, (state, action) => {
            state.statistics = action.payload as any
            state.loading = false
        })
        builder.addCase(getSubAccountChart$.fulfilled, (state, action) => {
            state.subAccountChart = action.payload as any
        })
    },
})

export const subAccountReducer = subAccountSlice.reducer

export const {
    setCurrentSubAccount,
    setCurrentSubAccountInfo,
    setDialogs,
    setWorkersForFilter,
} = subAccountSlice.actions

export {
    getAllSubAccounts$,
    getSubAccountPayout$,
    getSubAccountEarnings$,
    getStatistics$,
    updateSubAccountWallet,
    updateSubAccountReward$,
    getUserDetails,
    getSubAccountStatistics$,
    getSubAccountWorkers$,
    createSubAccount$,
    getSubAccountStratum$,
    getSubAccountSummary$,
    getSubAccountChart$,
}
